import researchPoster from "../images/PaideiaPoster.png"

export default function Research(){
    return (
        <div className="w-full">

            <div className="mt-5 bg-gray-600 rounded-md text-white">
            <div className="mt-5">
                <img src={researchPoster}></img> 
            </div>

                {/* <h1 className="text-3xl mt-5">Experience as a Machine Learning Research Assistant:</h1> */}
            </div>
            
        </div>
    )
}